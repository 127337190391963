// Copyright 2015 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

@import "~@blueprintjs/core/src/components/forms/common";
@import "~@blueprintjs/core/src/components/icon/icon-mixins";
@import "./common";

$timepicker-input-row-height: $pt-grid-size * 3 !default;
// subtract two because of inset shadow
$timepicker-input-row-inner-height: $timepicker-input-row-height - 2 !default;
// helps focus states of inputs line up correctly
$timepicker-row-padding: 0 1px !default;
$timepicker-divider-width: $pt-grid-size * 1.1 !default;
$timepicker-control-width: $pt-grid-size * 3.3 !default;

.#{$ns}-timepicker {
  white-space: nowrap;

  .#{$ns}-timepicker-arrow-row {
    padding: $timepicker-row-padding;
  }

  .#{$ns}-timepicker-arrow-button {
    @include pt-icon-colors();
    display: inline-block;
    padding: ($pt-grid-size * 0.4) 0;
    text-align: center;
    width: $timepicker-control-width;

    + .#{$ns}-timepicker-arrow-button {
      margin-left: $timepicker-divider-width;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .#{$ns}-timepicker-input-row {
    background: $input-background-color;
    border-radius: $pt-border-radius;
    box-shadow: $pt-input-box-shadow;
    display: inline-block;
    height: $timepicker-input-row-height;
    line-height: $timepicker-input-row-inner-height;
    padding: $timepicker-row-padding;
    vertical-align: middle;
  }

  .#{$ns}-timepicker-divider-text {
    color: $pt-text-color-muted;
    display: inline-block;
    font-size: $pt-font-size-large;
    text-align: center;
    width: $timepicker-divider-width;
  }

  .#{$ns}-timepicker-input {
    background: transparent;
    border: 0;
    border-radius: $pt-border-radius;
    box-shadow: input-transition-shadow($input-shadow-color-focus);
    color: $pt-text-color;
    height: $timepicker-input-row-inner-height;
    outline: 0;
    padding: 0;
    text-align: center;
    transition: $input-transition;
    width: $timepicker-control-width;

    &:focus {
      box-shadow: input-transition-shadow($input-shadow-color-focus, true);
    }

    @each $intent, $color in $pt-intent-colors {
      &.#{$ns}-intent-#{$intent} {
        @include pt-input-intent($color);

        .#{$ns}-dark & {
          @include pt-dark-input-intent(map-get($pt-dark-input-intent-box-shadow-colors, $intent));
        }
      }
    }

    @media (forced-colors: active) and (prefers-color-scheme: dark) {
      border: 1px solid $pt-high-contrast-mode-border-color;
    }
  }

  .#{$ns}-timepicker-ampm-select {
    margin-left: $pt-grid-size * 0.5;
  }

  &.#{$ns}-disabled {
    .#{$ns}-timepicker-input-row {
      @include pt-input-disabled();
    }

    .#{$ns}-timepicker-input,
    .#{$ns}-timepicker-divider-text {
      color: $input-color-disabled;
      cursor: not-allowed;
    }

    .#{$ns}-timepicker-arrow-button,
    .#{$ns}-timepicker-arrow-button:hover {
      color: $input-color-disabled;
      cursor: not-allowed;
    }
  }
}

.#{$ns}-dark .#{$ns}-timepicker {
  .#{$ns}-timepicker-input-row {
    background: $dark-input-background-color;
    box-shadow: $pt-dark-input-box-shadow;
  }

  .#{$ns}-timepicker-divider-text {
    color: $pt-dark-text-color-muted;
  }

  .#{$ns}-timepicker-input {
    color: $pt-dark-text-color;
  }
}
